<template>
  <div
    class="remove-border--left remove-border--y add-border layout-wrapper-bg"
  >
    <div class="column full-height justify-between no-wrap">
      <div class="relative-position">
        <NuxtLink
          to="/"
          class="flex flex-col! items-center justify-center"
          style="height: 50px"
        >
          <Logo
            v-if="store.showWsc"
            variant="haz-h"
            alt="hazcheckLogo"
            data-cy="secondaryLogo"
            height="25px"
            width="25px"
            class="size-[25px] object-contain"
          />
        </NuxtLink>
        <Separator />
        <suspense>
          <LayoutRail />
        </suspense>
      </div>
      <div class="column full-width pt-4!">
        <div class="column full-width items-center pb-4!">
          <template v-if="store.isLoggedIn">
            <ClientOnly>
              <RealTimeStatus class="mb-1!" />
              <template #fallback>
                <div class="mb-2!">
                  <q-skeleton type="QChip" style="height: unset; width: 76px" />
                </div>
              </template>
            </ClientOnly>
          </template>
          <div class="full-width text-center mb-8!">
            <Separator class="full-width" />
            <div class="text-subtitle2" style="font-size: 12px">
              {{ config.public.version }}
              
            </div>
            <Separator class="full-width" />
          </div>

          <Logo
            v-if="store.showWsc"
            variant="wsc"
            height="35px"
            class="px-2 pb-1 object-contain"
          />
          <Logo
            variant="ncb-square"
            height="40px"
            class="px-3 object-contain"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { NuxtLink, LayoutRail, Logo, RealTimeStatus } from "#components";
import { useRuntimeConfig, useUserStore } from "#imports";

defineProps<{
  leftDrawerOpen: boolean;
}>();
defineEmits(["click:toggle-drawer"]);

const store = useUserStore();
const config = useRuntimeConfig();
</script>

<style lang="scss" scoped>
hr {
  margin-block-end: 0;
  margin: 0;
}
</style>

<template>
  <transition name="horizontal-menu">
    <Card v-if="optionsMenuOpen" class="floating-menu pb-4!">
      <CardHeader hint="You can drag these onto your sidebar" class="pb-0">
        <template #hint>
          <span class="text-subtitle2" style="font-size: 12px">
            {{ $t("rail_menu.subtitle") }}
          </span>
        </template>
        {{ $t("rail_menu.title") }}
      </CardHeader>

      <DraggableList
        ref="menu"
        v-model="linksExtra"
        wrapper-classes="floating-menu-list"
        item-wrapper-classes="floating-menu-list-item"
        :handle="false"
      >
        <template #listItem="{ item }">
          <LayoutRailLink style="width: 84px" :link="item.value" />
        </template>
      </DraggableList>
    </Card>
  </transition>

  <DraggableList
    ref="rail"
    v-model="links"
    :handle="false"
    :wrapper-classes="`rail-drag-list ${menu?.drag ? 'rail-min-height' : ''}`"
  >
    <template #listItem="{ item }">
      <LayoutRailLink style="width: 84px; height: 64px" :link="item.value" />
    </template>
  </DraggableList>

  <LayoutRailLink
    v-if="extraLinks.length"
    class="relative"
    style="width: 84px; height: 64px"
    :link="{
      id: 'more',
      icon: 'mdi:dots-horizontal',
      title: 'More',
      callback: () => {
        openedFromMoreButton = !optionsMenuOpen;
        optionsMenuOpen = !optionsMenuOpen;
      },
    }"
  />
</template>

<script lang="ts" setup>
import { CardHeader, DraggableList } from "#components";
import { pageAreaClicked, useEventBus } from "#imports";
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useRailLinks } from "~/composables/useRailLinks";

import type { ComponentExposed } from "~/utils/typeHelpers";

// for rail
const rail = ref<ComponentExposed<typeof DraggableList>>();
// for hover menu
const menu = ref<ComponentExposed<typeof DraggableList>>();

const {
  sideBarLinks,
  extraLinks,
  moreOptionsMenuOpen: optionsMenuOpen,
  setVisibleLinks,
} = await useRailLinks();
const links = computed({
  get() {
    return sideBarLinks.value.map((link) => ({ value: link, name: link.id }));
  },
  set(val) {
    setVisibleLinks(val.map((link) => link.value.id));
  },
});

const linksExtra = ref(
  extraLinks.value.map((x) => ({
    value: x,
    name: x.id,
  }))
);

const openedFromMoreButton = ref<boolean>(false);

const dismissMenu = () => {
  optionsMenuOpen.value = false;
  openedFromMoreButton.value = false;
};

if (import.meta.client)
  document.addEventListener("keydown", (e) => {
    if (e.key === "Escape") dismissMenu();
  });

const { on: onViewportClicked } = useEventBus(pageAreaClicked);

const router = useRouter();
router.beforeEach(dismissMenu);
onViewportClicked(dismissMenu);

watch(
  () => rail.value?.drag,
  (val) => {
    if (openedFromMoreButton.value) return;
    if (val === undefined) return;
    optionsMenuOpen.value = val;
    if (!val) openedFromMoreButton.value = false;
  }
);
</script>

<style lang="scss">
@import "~/assets/css/app.scss";

$cols: 3;
.floating-menu {
  z-index: 5999;
  position: absolute;
  left: calc(100% + 16px);
  top: $headerHeight + 16px;
  min-width: $cols * $sidebarWidth;
}

.floating-menu-list-item {
  width: $sidebarWidth;
  height: 64px;
}
.floating-menu-list {
  min-height: 100px;
  margin: 4px 0;
  gap: 4px;
  display: grid;
  grid-template-columns: repeat($cols, 1fr);
}

.rail-drag-list {
  min-height: 0px;
  transition: min-height 0.1s ease-in-out;
}

.rail-min-height {
  min-height: 200px;
}
</style>

<template>
  <div
    class="full-width add-border remove-border--x remove-border--top px-2! layout-wrapper-bg flex-row! flex items-center justify-end"
  >
    <ButtonLink
      v-if="!isLoggedIn"
      class="px-2!"
      variant="flat"
      icon="mdi:login-variant"
      label="user.sign_in"
      external
      :to="getLoginUrl()"
    />

    
    <LayoutAvatar v-if="isLoggedIn" has-menu />
  </div>
</template>

<script lang="ts" setup>
import { LayoutAvatar, ButtonLink } from "#components";
import useAuth from "~/composables/useAuth";
import { useBreakUi } from "~/composables/useBreakUi";

const { broken, toggle, depth } = useBreakUi();
const { isLoggedIn, getLoginUrl } = await useAuth();

function updateDepth(d: string | number | null) {
  if (d === null) {
    depth.value = 1;
  } else if (typeof d === "string") {
    depth.value = parseInt(d);
  } else {
    depth.value = d;
  }
}
</script>

<style lang="scss" scoped>
hr {
  margin-block-end: 0;
  margin: 0;
}
</style>

<template>
  <q-item
    :key="link.id"
    active-class="bg-main-3"
    :to="
      link.alert || !link.linkName
        ? undefined
        : { path: link.linkName, force: true }
    "
    :href="link.externalLink && link.linkName ? link.linkName : undefined"
    :target="link.externalLink && link.linkName ? '_blank' : undefined"
    class="p-0! select-none"
    clickable
    tag="router-link"
    @click="linkClicked(link)"
  >
    <slot />
    <q-menu v-if="link.children" anchor="center right" self="center left">
      <Card class="flex gap-2 no-wrap p-2!">
        <LayoutRailLink
          v-for="childLink in link.children"
          :key="childLink.title"
          :link="childLink"
        />
      </Card>
    </q-menu>
    <q-menu
      v-if="link.alert"
      v-model="isAlertOpen"
      anchor="center end"
      self="center start"
      :offset="[10, 0]"
    >
      <Component
        :is="link.alertComponent"
        @click:confirm="goToLink(link)"
        @click:cancel="cancelClicked()"
      />
    </q-menu>

    <q-item-section
      v-if="link.icon"
      avatar
      class="flex flex-col items-center flex-nowrap! justify-center full-width p-2!"
    >
      <Icon size="md" class="shrink-0" :name="link.icon" />
      <div class="row items-center justify-center text-center mt-1!">
        <span class="mini-text text-strong">
          {{ $t(link.title) }}
        </span>
      </div>
    </q-item-section>
  </q-item>
</template>

<script setup lang="ts">
import { navigateTo } from "#imports";
import { ref } from "vue";

import type { RailLink } from "~/types/railLink";

defineProps<{ link: RailLink }>();

const isAlertOpen = ref(false);

const goToLink = (link: RailLink) =>
  navigateTo({ name: link.linkName, force: true });

const cancelClicked = () => (isAlertOpen.value = false);

const linkClicked = (link: RailLink) => {
  if (link.children) return;
  if (isAlertOpen.value) goToLink(link);
  if (link.callback) link.callback();
};
</script>

<style lang="scss">
.mini-text {
  font-size: 10px;
  user-select: none;
  text-transform: capitalize;
}
</style>

import { useState } from "#imports";
import { useQueryClient } from "@tanstack/vue-query";
import { useDebounceFn } from "@vueuse/shared";
import { computed } from "vue";
import { useCaseFilters } from "~/composables/useCaseFilters";
import { useUserService } from "~/src/services/UserService";
import { useUserStore } from "~/stores/userStore";
import { QUERY_KEYS } from "~/utils/queryKeys";
import { filterMap } from "~/utils/rust";

import type { RailLink } from "~/types/railLink";

export const useRailLinks = async () => {
  const queryClient = useQueryClient();
  const store = useUserStore();
  const { resetRequest } = useCaseFilters();
  const { useRailLinksQuery, useRailLinksMutation } = useUserService();

  const { mutate: updateRailLinksMutation } = useRailLinksMutation();

  const moreOptionsMenuOpen = useState("moreOptionsMenuOpen", () => false);

  const defaultLinks = computed(() => {
    if (!store.isLoggedIn) return [];

    const defaultLinks: RailLink[] = [
      {
        id: "user_guide",
        title: "general.user_guide",
        icon: "mdi:book-open-page-variant",
        linkName:
          "https://docs.hazcheck.com/external/manual/hazcheck-detect-cargo-safety-program/article/title-page?p=2d9095e223520abfd53a7f8c02e872df2dc61f3a6cc5177a5958e98df3e9d931adc0434203e7c69bc9500f10efd9f4c5",
        externalLink: true,
      },
      {
        id: "api-docs",
        title: "feature.api_docs",
        icon: "mdi:xml",
        linkName: "/docs/hcd",
      },
      {
        id: "cm-api-docs",
        title: "feature.cm_api_docs",
        icon: "mdi:xml",
        linkName: "/docs/cm",
      },
    ];

    if (store.hasPermission.Case.read) {
      defaultLinks.push({
        id: "cases",
        title: "feature.cases",
        icon: "mdi:clipboard-check-multiple",
        linkName: "/cases",
        callback: () => resetRequest(),
      });
    }

    if (store.hasPermission.Library.read) {
      defaultLinks.push({
        id: "libraries",
        title: "feature.libraries",
        icon: "mdi:book-open",
        linkName: "/libraries",
      });
    }

    if (store.hasPermission.Emailtemplate.read) {
      defaultLinks.push({
        id: "email_templates",
        title: "feature.email_templates",
        icon: "mdi:email",
        linkName: "/email-templates",
      });
    }

    if (store.hasPermission["containerinspectioncompany"].read) {
      defaultLinks.push({
        id: "inspections",
        title: "feature.inspections",
        icon: "mdi:camera-document",
        linkName: "/inspections/surveyors",
      });
    }

    if (store.hasPermission["globalverifiedshipper"].search) {
      defaultLinks.push({
        id: "verified_shippers",
        title: "feature.verified_shippers",
        icon: "mdi:ferry",
        linkName: "/verified-shippers",
      });
    }

    if (store.hasPermission.Analytics.read) {
      defaultLinks.push({
        id: "analytics",
        title: "feature.analytics",
        icon: "mdi:chart-box-outline",
        children: [
          {
            id: "analytics_global",
            title: "analytics.global",
            icon: "mdi:earth",
            linkName: "/analytics/global",
          },
          {
            id: "analytics_incidents",
            title: "analytics.incidents",
            icon: "mdi:exit-run",
            linkName: "/analytics/incidents",
          },
        ],
      });
    }

    return defaultLinks;
  });
  const defaultExtraLinks = computed(() => {
    if (!store.isLoggedIn) return [];

    const defaultExtraLinks: RailLink[] = [];

    if (store.hasPermission.Policy.read) {
      defaultExtraLinks.push({
        id: "policies",
        title: "feature.policies",
        icon: "mdi:ballot",
        linkName: "/policies",
      });
    }

    if (store.hasPermission.incident.read) {
      defaultExtraLinks.push({
        id: "incidents",
        title: "feature.incidents",
        icon: "mdi:exit-run",
        linkName: "/incidents",
      });
    }

    if (store.hasPermission["Template"].read) {
      defaultExtraLinks.push({
        id: "template_groups",
        title: "feature.template_groups",
        icon: "mdi:vector-triangle",
        linkName: "/template-groups",
      });
    }

    if (store.hasPermission["Template"].read) {
      defaultExtraLinks.push({
        id: "data_blocks",
        title: "feature.data_blocks",
        icon: "mdi:data-matrix",
        linkName: "/data-blocks",
      });
    }

    return defaultExtraLinks;
  });
  const allVisibleLinks = computed(() => [
    ...defaultLinks.value,
    ...defaultExtraLinks.value,
  ]);

  const queryEnabled = computed(() => store.isLoggedIn);

  const { data: serverLinks } = await useRailLinksQuery({
    enabled: queryEnabled,
    keepPreviousData: true,
  });

  const debounced = useDebounceFn((links: string[]) => {
    console.log("debounced update to rail links");
    updateRailLinksMutation({ links });
  }, 1000);
  function setVisibleLinks(links: string[]) {
    // to fix vue query complaining about proxies (doesn't actually fix it)
    const _links = [...links];
    // debounced optimisitic update
    queryClient.setQueryData([QUERY_KEYS.Users.Rail.get], _links);
    debounced(_links);
  }

  const links = computed(() => {
    if (!serverLinks.value) return null;

    const links = filterMap(serverLinks.value, (link) => {
      const found = allVisibleLinks.value.find(
        (visibleLink) => visibleLink.id === link
      );
      if (found) return found;
      else return filterMap.None;
    });
    const extraLinks = allVisibleLinks.value.filter(
      (visibleLink) => !serverLinks.value!.includes(visibleLink.id)
    );

    return [links, extraLinks];
  });

  const sideBarLinks = computed(() =>
    serverLinks.value ? links.value![0] : defaultLinks.value
  );
  const extraLinks = computed(() =>
    serverLinks.value ? links.value![1] : defaultExtraLinks.value
  );

  return {
    allVisibleLinks,
    sideBarLinks,
    extraLinks,
    moreOptionsMenuOpen,

    setVisibleLinks,
  };
};

<template>
  <div class="flex items-stretch no-wrap bg-main-2">
    <LayoutDrawer
      class="sidebarWidth height-screen"
      :left-drawer-open="leftDrawerOpen"
      @click:toggle-drawer="toggleLeftDrawer"
    />

    <div class="inverseSidebarWidth" @click="onMainClicked">
      <LayoutHeader
        class="headerHeight"
        @click:toggle-drawer="toggleLeftDrawer"
      />
      <NuxtErrorBoundary @error="console.error">
        <TooltipProvider :delay-duration="350">
          <main
            :class="fullWidth ? '' : 'container-xl'"
            class="column no-wrap p-4! inverseSidebarWidth inverseHeaderHeight overflow-y-auto overflow-x-hidden"
          >
            <LazyNuxtPage />
          </main>
          <template #error="{ error, clearError: wipeError }">
            <div padding class="bg-red text-white full-width">
              <div class="column items-start gap-2 px-4 pb-4">
                <h1 class="text-white">Oops! Something went wrong</h1>
                <div class="flex gap-2">
                  <Button
                    label="error.try_again"
                    variant="flat"
                    icon="mdi:reload"
                    @click.prevent="wipeError"
                  />
                  <Button
                    label="error.go_home"
                    variant="flat"
                    icon="mdi:home"
                    @click.prevent="goHome(wipeError)"
                  />
                </div>
                <code>{{ error }}</code>
              </div>
            </div>
          </template>
        </TooltipProvider>
      </NuxtErrorBoundary>
      <div
        id="sticky-spot"
        class="q-layout-padding z-top sticky pb-0! pt-0! bottom-0!"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { TooltipProvider } from "#components";
import { useQuasar } from "quasar";
import { ref } from "vue";
import { navigateTo, useEventBus } from "#imports";
import { pageAreaClicked } from "~/utils/eventKeys";
import { useSocketInvalidation } from "~/composables/useSocketInvalidation";

defineProps<{
  fullWidth?: boolean;
}>();

const { emit: onMainClicked } = useEventBus(pageAreaClicked);
const $q = useQuasar();
const leftDrawerOpen = ref(true);

const goHome = async (wipeError: () => unknown) => {
  await navigateTo("/");
  await wipeError();
};

const toggleLeftDrawer = (e?: boolean) => {
  if (e !== undefined) {
    leftDrawerOpen.value = e;
    return;
  }

  if ($q.screen.lt.md) {
    leftDrawerOpen.value = !leftDrawerOpen.value;
  } else {
    leftDrawerOpen.value = true;
  }
};

useSocketInvalidation();
</script>

<style lang="scss" scoped>
@import "../assets/css/app.scss";

hr {
  margin-block-end: 0;
  margin: 0;
}

.sidebarWidth {
  width: $sidebarWidth;
}
.inverseSidebarWidth {
  width: calc(100vw - $sidebarWidth);
}

.headerHeight {
  height: $headerHeight;
}
.inverseHeaderHeight {
  height: calc(100vh - $headerHeight);
}

.height-screen {
  height: 100vh;
}
</style>
